import React, {useState} from "react";
import {DayPicker} from "react-day-picker";
import {format} from 'date-fns';
import 'react-day-picker/dist/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Button, Col, Container, Row, Spinner} from "react-bootstrap";

interface CampoPadel {
    id: number,
    nome: string,
}

export default function Padel() {
    const campiDisponibili: CampoPadel[] = [
        {
            id: 922,
            nome: "Raldon"
        },
        {
            id: 955,
            nome: "Legnago"
        },
    ];

    const [data, setData] = useState<Date | undefined>(new Date());
    const [campo, setCampo] = useState<CampoPadel>(campiDisponibili[0]);
    const [isIframeLoading, setIsIframeLoading] = useState<boolean>(false);

    let link = `https://www.radicalpadel.com/PROGRAMA/modulo_reservas/selector_pista_tabla.php?fecha=${format(data ?? 0, 'yyyy-MM-dd')}&idCampo=${campo.id}`;

    return (
        <>
            <Container fluid style={{width: '100%', minHeight: '100vh'}}>
                {isIframeLoading ? <Spinner
                    className="loading text-center"
                    color="white"
                /> : ''}
                <Row style={{minHeight: '100vh'}}>
                    <Col md={3} xs={12} lg={2}>
                        <DayPicker
                            mode="single"
                            selected={data}
                            onSelect={(date) => {
                                setIsIframeLoading(true);
                                setData(date);
                            }}
                        />
                        <div className="d-grip gap-2">
                            {
                                campiDisponibili.map((campoObj, i) => {
                                    return (
                                        <Button size="lg" variant={campo.id === campoObj.id ? 'success' : 'secondary'}
                                                onClick={() => {
                                                    setIsIframeLoading(true);
                                                    setCampo(campoObj);
                                                }}>
                                            {campoObj.nome}
                                        </Button>
                                    )
                                })
                            }
                        </div>
                    </Col>
                    <Col md={9} xs={12} lg={9}>
                        <iframe src={link} frameBorder={0} style={{height: '100%', width: '100%'}}
                                onLoad={() => setIsIframeLoading(false)} title="Radical"/>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
